import React, { useState } from "react";
// import "../App.css";

export const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <>
            {text > text.slice(0,400) ? (
                <p className="text">
                    {isReadMore ? text.slice(0, 400) : text}
                    <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? "...read more" : " show less"}
              </span>
                </p>
            ): (
                text
            )}

        </>

    );
};
