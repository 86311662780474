import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/signature2.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import jiggs from '../assets/aviant-customs-1.png';
import logo2 from '../assets/aviantcustomslogogold.png';
import crown from '../assets/crown.png';
// import zce1 from '../assets/zce1.jpg';
// import zce2 from '../assets/zce2.jpg';
// import zce3 from '../assets/zce3.jpg';
// import zce4 from '../assets/zce4.jpg';
import p1 from '../assets/p1.jpg';
import p2 from '../assets/p2.jpg';
import p3 from '../assets/p3.jpg';
import p4 from '../assets/p4.jpg';
import f1 from '../assets/f1.jpg';
import f2 from '../assets/f2.jpg';
import f3 from '../assets/f3.jpg';
import f4 from '../assets/f4.jpg';
import j1 from '../assets/j1.jpg';
import j2 from '../assets/j2.jpg';
import j3 from '../assets/j3.jpg';
import z1 from '../assets/z1.jpg';
import z2 from '../assets/z2.jpg';
import z3 from '../assets/z3.jpg';
import z4 from '../assets/z4.jpg';
import ringsthumb from '../assets/ringsthumb.png';
import chainsthumb from '../assets/chainsthumb.png';
// import briannathumb from '../assets/briannathumb.png';
import priyathumb from '../assets/priyathumb.png';

export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  logo2,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  jiggs,
  crown,
  // zce1,
  // zce2,
  // zce3,
  // zce4,
  p1,
  p2,
  p3,
  p4,
  f1,
  f2,
  f3,
  f4,
  j1,
  j2,
  j3,
  // z1,
  // z2,
  // z3,
  // z4,
  // ringsthumb,
  // chainsthumb,
  // briannathumb,
  // priyathumb

};
